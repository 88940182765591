import logo from './under-construction-gif.gif';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>Hello there.</p>
    <p>Welcome to the official website of Zak Farnworth. I'm under construction at the moment, but be sure to check back later.</p>
    <p>In the mean time, feel free to checkout some great music below.</p>
    <p><i>Zak Farnworth</i></p>
        <a
          className="App-link"
          href="https://www.bbc.co.uk/programmes/b00jlwqd"
          target="_blank"
          rel="noopener noreferrer"
        >
          Good Music to listen to whilst you wait.
        </a>
      </header>
    </div>
  );
}

export default App;
